export default {
    methods: {
        loadData: function(data, callback){
            this.$store.dispatch('getAskDetail', data)
            .then((rs)=>{
                callback && callback(rs, null)
            }).catch((error)=>{
                callback && callback(null, error)
            })
        }
    }
}