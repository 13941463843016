<template>
    <div id="ask-detail">
        <dl class="breadcrumb">
			<dt>您的位置:</dt>
			<dd><router-link to="/ask/list">咨詢醫師</router-link><svg t="1599534847217" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2986" width="16" height="16"><path d="M595.712 526.112l-205.44-197.28c-8.864-10.432-7.504-25.968 3.024-34.688 10.528-8.736 26.24-7.36 35.088 3.072l205.424 197.28c8.864 10.432 7.504 25.968-3.008 34.688S604.56 536.56 595.712 526.112z" p-id="2987" fill="#646464"></path><path d="M390.256 695.68l205.44-197.28c8.864-10.432 24.56-11.808 35.088-3.088 10.528 8.736 11.872 24.272 3.024 34.688l-205.44 197.28c-8.848 10.432-24.56 11.808-35.088 3.072C382.752 721.664 381.392 706.128 390.256 695.68z" p-id="2988" fill="#646464"></path></svg></dd>
			<dd><span>問答詳情</span></dd>
		</dl>
        <div class="detail" v-if="askDetail">
            <div class="header">
                <div class="user-info">
                    <div class="gender" v-text="askDetail.gender == 1 ? '男' : '女'"></div>
                    <div class="age" v-text="askDetail.age + '歲'"></div>
                    <div class="user" v-text="askDetail.createdtime"></div>
                </div>
                <div class="title-wrapper">
                    <router-link :to="`/ask/list?IllCate=${askDetail.illnesscategoryid}`" class="category" v-text="'#' + askDetail.illnesscategorycode"></router-link>
                    <h1 class="title" v-text="askDetail.title"></h1>
                </div>
                <p class="intro" v-text="'問題描述：' + askDetail.note"></p>
                <div class="sub-q" v-if="askDetail.subAskList.length">
                    <img src="../../../assets/imgs/pc/img_zhuiwen@2x.png" alt="" class="icon-sub-q">
                    <span class="sub-q-hint">追問:</span>
                    <span class="sub-q-value" v-text="askDetail.subAskList.length ? askDetail.subAskList[0].note : ''"></span>
                </div>
                <img src="../../../assets/imgs/pc/img_fenx@2x.png" alt="" class="share" @click="doShare">
            </div>
            <div class="img-list">
                <viewer :options="{toolbar: false, navbar: false}" class="item" v-for="(imgData, index) in askDetail.images" :key="index">
                    <img :src="imgData.image">
                </viewer>
            </div>
            <div class="answer-list">
                <section class="answer-item" v-for="(answer, index) in askDetail.answerList" :key="index">
                    <img src="../../../assets/imgs/pc/img_nanysy@2x.png" alt="" class="icon-doctor">
                    <div class="col">
                        <div class="row">
                            <span class="doctor-name" v-text="'醫師'+answer.doctorname"></span>
                            <span class="create-time" v-text="answer.createdtime"></span>
                        </div>
                        <p class="answer-value" v-html="revert(answer.note)"></p>
                    </div>
                </section>
            </div>
            <div class="un-answer" v-if="! askDetail.answerList.length"></div>
        </div>
        <loading v-if="loading" />
        <my-share :data="shareData" v-if="share" :onResult="()=>share=false"/>
    </div>
</template>

<script>
    import MyShare from '@/layouts/MyShare.vue'
    import Loading from '@/layouts/Loading.vue'
    import ask_detail from '@/views/mixins/ask_detail'
	export default {
        mixins: [ask_detail],
        components: {
            Loading,
            MyShare,
        },
        data: function(){
            return {
                share: false,
                shareData: null,
                askDetail: null,
                loading: true,
            }
        },
        methods: {
            revert: function(str) {
                const rs = this.urlDecode(str || '');
                if(!str || str == rs) {
                    return str;
                } else {
                    return `<a href="${str}" target="_blank">${rs}</a>`
                }
            },
            urlDecode: function(zipStr) {
                var uzipStr = '';
                for (var i = 0; i < zipStr.length; i += 1) {
                    var chr = zipStr.charAt(i);
                    if (chr === '+') {
                        uzipStr += ' ';
                    } else if (chr === '%') {
                        var asc = zipStr.substring(i + 1, i + 3);
                        if (parseInt('0x' + asc) > 0x7f) {
                            uzipStr += decodeURI('%' + asc.toString() + zipStr.substring(i + 3, i + 9).toString());
                            i += 8;
                        } else {
                            uzipStr += AsciiToString(parseInt('0x' + asc));
                            i += 2;
                        }
                    } else {
                        uzipStr += chr;
                    }
                }
                return uzipStr;
            },
            StringToAscii: function(str) {
                return str.charCodeAt(0).toString(16);
            },
            AsciiToString: function(asccode) {
                return String.fromCharCode(asccode);
            },
            onRefresh: function(){
                this.loading = true
                this.loadData({askid: this.$route.params.id}, (data, error)=>{
                    this.loading = false
                    if(data) {
                        this.askDetail = data
                    } else {
                        this._toast.warning(this, '加載失敗，請重試')
                    }
                })
            },
            doShare: function(){
                this.shareData = {
                    title: this.askDetail.title,
                    url: `${location.origin}/ask/detail/${this.askDetail.askid}`,
                    baseUrl: location.origin
                }
                this.share = true
            }
        },
        mounted: function(){
            var askid = this.$route.params.id
			if(parseInt(askid) == askid) {
				this.askDetail = JSON.parse(sessionStorage.getItem('detail'))
				this.loading = false
			} else {
				this.$router.push('/ask/list')
			}
        },
        watch: {
            '$route.params.id': function(askid) {
				if(parseInt(askid) == askid) {
					this.askDetail = JSON.parse(sessionStorage.getItem('detail'))
					this.loading = false
				} else {
					this.$router.push('/ask/list')
				}
            }
        }
    }
</script>

<style lang="scss" scoped>

    .ant-dropdown-menu {
        height: unset;
        overflow-y: unset;
    }

    #ask-detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .breadcrumb {
	   display: flex;
	   flex-direction: row;
	   width: 100%;
	   height: 32px;
	   box-sizing: border-box;
	   padding: 0px 16px;
	   background-color: #fff;
	   align-items: center;
	   font-size: 16px;
	   line-height: 17px;
	   font-weight: 400;
	   color: #969696;
	   user-select: none;
   }

	.breadcrumb dt {
		margin-right: 8px;
	}

	.breadcrumb dd {
	   color: #646464;
	   line-height: 17px;
	}

   .breadcrumb a {
	   margin: 0px 8px;
	   text-decoration: underline;
   }

   .breadcrumb span {
	   margin-left: 8px;
   }

    .breadcrumb svg {
        vertical-align: bottom;
    }

    .header {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 38px 20px 26px 20px;
        background-color: #fff;
        position: relative;
        margin-top: 7px;
    }

    .header > .share {
        position: absolute;
        right: 19px;
        top: 36px;
        cursor: pointer;
    }

    .header > .share > img {
        width: 21px;
        height: 21px;
    }

    .header > .user-info {
        display: flex;
        flex-direction: row;
        margin-bottom: 19px;
        font-weight: 400;
        color: #969696;
        font-size: 16px;
        line-height: 15px;
    }

    .header > .user-info > .gender {
        margin-right: 6px;
    }

    .header > .user-info > .age {
        margin-right: 12px;
    }

    .header > .title-wrapper > .category,
    .header > .title-wrapper > .title {
        display: inline;
        color: #242020;
        font-size: 24px;
        font-weight: bold;
        line-height: 25px;
    }

    .header > .title-wrapper > .category {
        color: #FF8000;
        margin-right: 8px;
    }

    .header > .intro {
        margin-top: 26px;
        color: #646464;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
    }

    .header > .sub-q {
        margin-top: 26px;
    }

    .header > .sub-q > .icon-sub-q {
        width: 15px;
        height: 15px;
        margin-right: 8px;
    }

    .header > .sub-q > .sub-q-hint {
        font-weight: bold;
        color: #72240C;
        font-size: 16px;
        line-height: 16px;
        margin-right: 9px;
    }

    .header > .sub-q > .sub-q-value {
        color: #242020;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
    }

    .img-list {
        background-color: #fff;
        padding: 0 20px 10px 20px;
    }

    .img-list > .item {
        display: inline-block;
        height: 200px;
        width: 200px;
        margin: 0px 8px 8px 0px;
    }

    .img-list img {
        display: inline-block;
        height: 200px;
        width: 200px;
        object-fit: fill;
    }

    .answer-list {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        background-color: #fff;
    }

    .answer-list > .answer-item {
        display: flex;
        flex-direction: row;
        padding: 20px 0px;
        margin: 0 16px;
        border-bottom: 1px solid #E8E8E8;
    }

    .answer-list > .answer-item:last-child {
        border-color: #fff;
    }

    .answer-list > .answer-item > .icon-doctor {
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    .answer-list > .answer-item .col {
        display: flex;
        flex-direction: column;
    }

    .answer-list > .answer-item .row {
        display: flex;
        flex-direction: row;
     }

    .answer-list > .answer-item .create-time,
    .answer-list > .answer-item .doctor-name {
        font-weight: 400;
        color: #969696;
        font-size: 14px;
        line-height: 13px;
        margin-right: 35px;
    }

    .answer-list > .answer-item .create-time {
        margin-right: 0;
    }

    .answer-list > .answer-item .answer-value {
        color: #242020;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 12px;
        word-wrap: break-word;
        word-break: break-all;
    }

    .answer-list > .answer-item .answer-value  a {
        color: #36C4D0;
        text-decoration: underline;
    }

    .un-answer {
        position: relative;
    }

    .un-answer::after {
        content: '\9084\6C92\6709\91AB\5E2B\56DE\7B54\8A72\554F\984C';
        color: #b7b7b7;
        font-size: 19px;
        font-weight: 400;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
    }
</style>
